<template>
  <el-container style="height: calc(100vh - 0px); display: flex; flex-direction: column;">
    <MainHeader />
    <el-container style="flex: 1; display: flex;">
      <SidebarLeft @selectMenu="handleMenuSelect" />
      <el-main class="main-content">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
        </el-breadcrumb>
        <component :is="componentsMap[currentComponent]" />
      </el-main>
    </el-container>
    <MainFooter />

    <!-- 悬浮帮助框组件 -->
<!--    <FloatingBox />-->
  </el-container>
</template>

<script setup>
import { ref } from "vue";
import MainHeader from "@/components/Common/MainHeader.vue";
import SidebarLeft from "@/components/Common/SidebarLeft.vue";
import MainFooter from "@/components/Common/MainFooter.vue";
import FloatingBox from "@/components/Common/FloatingBox.vue";

// 面包屑和当前组件
const breadcrumb = ref("填报中心");
const currentComponent = ref("ReportCenter");

// 动态导入 components/Datas 下的所有组件
const componentsMap = {};
const modules = require.context("@/components/Datas", false, /\.vue$/);
modules.keys().forEach((fileName) => {
  const componentConfig = modules(fileName);
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1"); // 使用文件名作为组件名
  componentsMap[componentName] = componentConfig.default || componentConfig;
});

const handleMenuSelect = ({ breadcrumbLabel, componentName }) => {
  breadcrumb.value = breadcrumbLabel;
  currentComponent.value = componentName;
};
</script>

<style scoped>
.main-content {
  padding: 10px;
  flex: 1;
}

.el-breadcrumb {
  margin-bottom: 20px;
}

.el-container {
  width: 100%;
}
</style>
