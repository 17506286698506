<template>
  <el-aside width="220px" class="aside-menu">
    <el-menu
        :default-active="activeMenu"
        :unique-opened="true"
        class="el-menu-vertical"
        @select="handleMenuSelect">

      <div class="menu-section-title">工作区</div>
      <el-menu-item
          index="1-1"
          :class="{'active-menu': activeMenu === '1-1'}"
          @click="handleMenuSelect('填报中心', 'ReportCenter', '1-1')">
        填报中心
      </el-menu-item>
      <el-menu-item
          index="1-2"
          :class="{'active-menu': activeMenu === '1-2'}"
          @click="handleMenuSelect('服务单位列表', 'CompanyList', '1-2')">
        服务单位列表
      </el-menu-item>
      <el-menu-item
          index="1-3"
          :class="{'active-menu': activeMenu === '1-3'}"
          @click="handleMenuSelect('核查机构列表', 'VerificationList', '1-3')">
        核查机构列表
      </el-menu-item>

      <div class="menu-section-title">设置区</div>
      <el-menu-item
          index="2-1"
          :class="{'active-menu': activeMenu === '2-1'}"
          @click="handleMenuSelect('文档模板', 'DocumentTemplate', '2-1')">
        文档模板列表
      </el-menu-item>
      <el-menu-item
          index="2-2"
          :class="{'active-menu': activeMenu === '2-2'}"
          @click="handleMenuSelect('基础目录', 'DocumentDefault', '2-2')">
        基础目录列表
      </el-menu-item>
<!--      <el-menu-item-->
<!--          index="2-3"-->
<!--          :class="{'active-menu': activeMenu === '2-3'}"-->
<!--          @click="handleMenuSelect('模板附件管理', 'DocumentManagement', '2-3')">-->
<!--        模板附件管理-->
<!--      </el-menu-item>-->
      <el-menu-item
          index="2-4"
          :class="{'active-menu': activeMenu === '2-4'}"
          @click="handleMenuSelect('计算公式库', 'FormulaLibrary', '2-4')">
        计算公式库
      </el-menu-item>
      <el-menu-item
          index="2-5"
          :class="{'active-menu': activeMenu === '2-5'}"
          @click="handleMenuSelect('相关参数缺省值', 'DefaultParameters', '2-5')">
        相关参数缺省值
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emits = defineEmits(['selectMenu']);

const activeMenu = ref("1-1");

const handleMenuSelect = (breadcrumbLabel, componentName, menuIndex) => {
  emits('selectMenu', { breadcrumbLabel, componentName, menuIndex });
  activeMenu.value = menuIndex;
};
</script>

<style scoped>
.aside-menu {
  background-color: var(--lightgray-color);
  color: white;
}

.el-menu {
  border: none;
}

/* 一级区域标题样式 */
.menu-section-title {
  font-weight: bold;
  font-size: 16px;
  color: var(--primary-color);
  padding: 10px 15px;
}

/* 动态菜单样式 */
.active-menu {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
}
/* 确保默认文本颜色 */
.el-menu-item {
  color: var(--text-color) !important; /* 使用全局变量控制默认文本颜色 */
}

/* 激活状态下的文本颜色 */
.el-menu-item.is-active, .active-menu {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important; /* 保持激活状态下的文本颜色一致 */
}

/* 鼠标悬停状态 */
.el-menu-item:hover {
  color: var(--primary-color) !important;
}
/* 禁止 el-menu-item 中的文本选择 */
.el-menu-item {
  user-select: none; /* 禁止用户选择文本 */
}
/* 确保默认文本颜色 */
.el-menu-item {
  color: var(--text-color) !important; /* 使用默认文本颜色 */
}

/* 激活状态下的文本颜色和背景色 */
.active-menu {
  background-color: var(--secondary-color) !important; /* 黄色背景 */
  color: var(--white-color) !important; /* 白色文字，增加对比 */
}

/* 悬停效果 */
.el-menu-item:hover {
  color: var(--primary-color) !important; /* 悬停时使用主品牌色 */
}

</style>
