<template>
  <div class="data-center-option">
    <!-- 新增缺省表按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-report-button" @click="openAddDialog">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增缺省表</span>
      </el-button>
      <div class="toolbar">
        <el-input v-model="searchKeyword" placeholder="请输入关键词搜索">
          <template #prefix>
            <el-icon><Search /></el-icon>
          </template>
        </el-input>
      </div>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="default" @click="restSearch">重置</el-button>
    </div>

    <!-- 表格列表 -->
    <el-table :data="paginatedData" border style="width: 100%;height: 70vh" stripe>
      <!-- 表格列 -->
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="table_name" label="表名称" width="150"/>
      <el-table-column prop="description" label="表描述" />
      <el-table-column label="操作" width="250">
        <template #default="{ row }">
          <el-button type="warning" size="small" @click="edit(row)">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteTable(row)">删除</el-button>
          <el-button type="primary" size="small" @click="viewRow(row)">查看数据</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 查看数据弹窗 -->
    <el-dialog v-model="showDataDialog" :title="currentFormula.name" :close-on-click-modal="false" width="80%" style="text-align: center">
      <div class="table-container">
        <!-- 搜索框 -->
        <el-input
            v-model="searchQuery"
            placeholder="请输入搜索内容"
            clearable
            style="margin-bottom: 10px; width: 300px;"
            @input="filterTableData"
        >
          <template #prefix>
            <el-icon><Search /></el-icon>
          </template>
        </el-input>
        <span style="color: red;margin-left: 10px;font-size: 12px">提示：可以搜索任何内容</span>

        <!-- 表格 -->
        <el-table
            :data="filteredData"
            border
            style="width: 100%;"
            stripe
            :height="400"
            header-row-class-name="sticky-header"
        >
          <el-table-column
              v-for="(field, index) in visibleFields"
              :key="index"
              :prop="field.name"
              :label="field.label"
          >
            <template #default="{ row }">
              <el-input
                  v-model="row[field.name]"
                  placeholder="请输入内容"
                  v-if="field.editable"
                  style="width: 100%; cursor: pointer"
                  @change="handleFieldChange(row, field.name)"
              />
              <span v-else>{{ row[field.name] }}</span>
            </template>
          </el-table-column>

          <!-- 操作列 -->
          <el-table-column label="操作" width="100">
            <template #default="{ row }">
              <el-button type="danger" size="small" @click="deleteRow(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="addEmptyRow">新增数据</el-button>
          <el-button @click="closeDataDialog">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import axios from "axios";
import { ref, reactive, computed, onMounted } from "vue";
import { CirclePlus } from "@element-plus/icons-vue";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "DefaultParameters",
  components: {
    CirclePlus,
  },
  setup() {
    const tableData = ref([]);
    const totalItems = ref(0);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const showDialog = ref(false);
    const isEditing = ref(false);
    const showDataDialog = ref(false);
    const tableDataFields = ref([]);
    const filteredData = ref([]); // 用于展示的过滤数据
    const searchQuery = ref(""); // 搜索关键词
    const searchKeyword = ref(""); // 搜索关键词
    const tableFields = ref([]);

    const currentFormula = reactive({
      name: "",
      table_name: "",
      description: "",
    });

    const rules = {
      name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      table_name: [{ required: true, message: "请输入表名称", trigger: "blur" }],
      description: [{ required: true, message: "请输入表描述", trigger: "blur" }],
    };

    const visibleFields = computed(() => {
      return tableFields.value.filter((field) => field.visible);
    });

    const paginatedData = computed(() => (Array.isArray(tableData.value) ? tableData.value : []));

    const handleSearch = async () => {
      console.log("搜索关键词:", searchKeyword.value); // 调试输出
      fetchData();
    };
    const restSearch = async () => {
      searchKeyword.value = "";
      fetchData();
    };

    const fetchData = async () => {
      try {

        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/parameterstable/list`,
            { page: currentPage.value, pageSize: pageSize.value,  keywords: searchKeyword.value },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        if (response.data?.code === 1) {
          tableData.value = response.data.data.data || [];
          totalItems.value = response.data.data.total || tableData.value.length;
        } else {
          ElMessage.error(response.data.msg || "加载表格列表失败");
        }
      } catch (error) {
        console.error("获取表格列表失败:", error);
      }
    };
    const openAddDialog = () => {
      resetForm();
      isEditing.value = false;
      showDialog.value = true;
    };
    const closeDialog = () => {
      showDialog.value = false;
      resetForm();
    };

    const resetForm = () => {
      Object.assign(currentFormula, {
        id: null,
        name: "",
        table_name: "",
        description: "",
      });
    };
    const edit = (row) => {
      Object.assign(currentFormula, {
        id: row.id,
        name: row.name,
        table_name: row.table_name,
        description: row.description,
      });
      isEditing.value = true;
      showDialog.value = true;
    };
    const saveTable = async () => {
      const apiUrl = isEditing.value
          ? `${process.env.VUE_APP_HOST}/api/parameterstable/edit`
          : `${process.env.VUE_APP_HOST}/api/parameterstable/add`;
      try {
        const response = await axios.post(
            apiUrl,
            {
              id: currentFormula.id,
              name: currentFormula.name,
              table_name: currentFormula.table_name,
              description: currentFormula.description,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
        );
        if (response.data && response.data.code === 1) {
          ElMessage.success(`${isEditing.value ? "编辑成功" : "添加成功"}`);
          fetchData();
          closeDialog();
        } else {
          ElMessage.error(response.data.msg || "保存失败");
        }
      } catch (error) {
        ElMessage.error("保存请求失败");
        console.error("保存失败:", error);
      }
    };


    const viewRow = async (row) => {
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/parameterstable/view`,
            { table_name: row.table_name },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        if (response.data?.code === 1) {
          currentFormula.table_name = row.table_name;
          currentFormula.id = row.id;
          currentFormula.name = row.name;
          tableFields.value = response.data.data.fields.map((field) => ({
            name: field.name,
            label: field.comment || field.name,
            editable: field.name !== "id", // 禁用编辑id
            visible: field.name !== "id", // 隐藏id列
          }));
          tableDataFields.value = response.data.data.rows || [];
          filteredData.value = [...tableDataFields.value]; // 初始化过滤数据
          showDataDialog.value = true;
        } else {
          ElMessage.error(response.data.msg || "加载数据失败");
        }
      } catch (error) {
        console.error("加载表数据失败:", error);
        ElMessage.error("加载表数据失败");
      }
    };

    const filterTableData = () => {
      if (!searchQuery.value) {
        filteredData.value = [...tableDataFields.value];
      } else {
        const query = searchQuery.value.toLowerCase();
        filteredData.value = tableDataFields.value.filter((row) =>
            Object.values(row).some(
                (value) => value != null && String(value).toLowerCase().includes(query)
            )
        );
      }
    };
    const deleteTable = async (row) => {
      try {
        await ElMessageBox.confirm("确定删除该表吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/parameterstable/delete`,
            {
              id: row.id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
        );
        if (response.data && response.data.code === 1) {
          ElMessage.success("删除成功");
          fetchData();
        } else {
          ElMessage.error(response.data.msg || "删除失败");
        }
      } catch (error) {
        if (error !== "cancel") {
          ElMessage.error("删除请求失败");
          console.error("删除失败:", error);
        }
      }
    };
    const deleteRow = async (row) => {
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/parameterstable/deleteRow`,
            { table_name: currentFormula.table_name, id: row.id },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        if (response.data?.code === 1) {
          ElMessage.success("删除成功");
          tableDataFields.value = tableDataFields.value.filter((item) => item.id !== row.id);
          filterTableData(); // 更新过滤数据
        } else {
          ElMessage.error(response.data.msg || "删除失败");
        }
      } catch (error) {
        console.error("删除失败:", error);
        ElMessage.error("删除失败");
      }
    };
    const addEmptyRow = () => {
      const emptyRow = {};

      // 初始化空行的每个字段
      tableFields.value.forEach((field) => {
        emptyRow[field.name] = field.name === "id" ? null : "";
      });

      // 将空行添加到数据源
      tableDataFields.value.unshift(emptyRow);
      filteredData.value = [...tableDataFields.value];
    };
    const handleFieldChange = async (row, fieldName) => {
      try {
        // 如果 ID 不存在，调用新增接口
        if (!row.id) {
          const response = await axios.post(
              `${process.env.VUE_APP_HOST}/api/parameterstable/addRow`,
              {
                table_name: currentFormula.table_name,
                data: row,
              },
              { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
          );

          if (response.data?.code === 1) {
            row.id = response.data.data.id; // 保存生成的 ID
            ElMessage.success("新增成功");
          } else {
            ElMessage.error(response.data.msg || "新增失败");
          }
        } else {
          // 如果 ID 存在，调用更新接口
          const response = await axios.post(
              `${process.env.VUE_APP_HOST}/api/parameterstable/update`,
              {
                table_name: currentFormula.table_name,
                id: row.id,
                field: fieldName,
                value: row[fieldName],
              },
              { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
          );

          if (response.data?.code === 1) {
            ElMessage.success("保存成功");
          } else {
            ElMessage.error(response.data.msg || "保存失败");
          }
        }
      } catch (error) {
        console.error("保存失败:", error);
        ElMessage.error("保存失败");
      }
    };

    const closeDataDialog = () => {
      showDataDialog.value = false;
      tableDataFields.value = [];
      filteredData.value = [];
      tableFields.value = [];
    };

    onMounted(fetchData);

    return {
      tableData,
      totalItems,
      currentPage,
      pageSize,
      showDialog,
      isEditing,
      showDataDialog,
      tableDataFields,
      filteredData,
      tableFields,
      currentFormula,
      rules,
      paginatedData,
      fetchData,
      openAddDialog,
      edit,
      closeDialog,
      resetForm,
      saveTable,
      viewRow,
      filterTableData,
      handleFieldChange,
      deleteRow,
      closeDataDialog,
      visibleFields,
      searchQuery,
      deleteTable,
      handleSearch,
      searchKeyword,
      restSearch,
      addEmptyRow
    };
  },
};
</script>

<style scoped>
.data-center-option {
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.add-report-button {
  /*background-color: var(--primary-color);*/
  color: var(--white-color);
}

.add-report-button:hover {
  background-color: var(--secondary-color);
}

.el-table th,
.el-table td {
  text-align: center;
}

.dialog-footer {
  text-align: right;
}
.table-input {
  border: none; /* 去掉边框 */
  background-color: transparent; /* 背景透明 */
  padding: 0;
  text-align: center; /* 居中对齐 */
}

.el-input:hover {
  border: none; /* 鼠标悬停显示边框 */
  background-color: #f9f9f9; /* 添加背景颜色变化 */
  cursor: pointer;
}

.el-input:focus {
  outline: none; /* 去掉默认焦点样式 */
  border: 1px solid var(--primary-color); /* 焦点边框颜色 */
}
.el-input__wrapper{
  border: none!important;
  background:none!important;
}
.el-input{
  border: none!important;
  background:none!important;
}
.el-input__inner{
  cursor: pointer !important;
}
.table-container {
  max-height: 500px; /* 限制表格的最大高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
}
.el-table .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white; /* 表头背景颜色 */
}
/* 固定表头 */
.sticky-header {
  position: sticky;
  top: 0; /* 距离顶部0像素 */
  z-index: 10; /* 确保表头覆盖内容 */
  background-color: #f5f7fa; /* 表头背景颜色 */
}

/* 表头样式 */
.el-table th {
  text-align: center; /* 表头文字居中 */
  font-weight: bold; /* 表头加粗 */
  background-color: #f5f7fa; /* 表头背景色 */
  border-bottom: 1px solid #ebeef5; /* 表头底部边框 */
}

/* 表格内容样式 */
.el-table td {
  text-align: center; /* 内容文字居中 */
}
.el-dialog__header.show-close{
  text-align: center;
}

</style>
