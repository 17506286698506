<template>
  <div class="data-center-option">
    <!-- 新增公式按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-report-button" @click="openAddDialog">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增公式</span>
      </el-button>
      <div class="toolbar">
        <el-input v-model="searchKeyword" placeholder="请输入关键词搜索">
          <template #prefix>
            <el-icon><Search /></el-icon>
          </template>
        </el-input>
      </div>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="default" @click="resetSearch">重置</el-button>
    </div>

    <!-- 新增/编辑公式对话框 -->
    <el-dialog :title="isEditing ? '编辑公式' : '新增公式'" v-model="showDialog" min-width="700px">
      <el-form :model="currentFormula" label-width="120px" ref="formulaForm">
        <!-- 公式名称 -->
        <el-form-item label="公式名称" prop="name" :rules="[{ required: true, message: '请输入公式名称', trigger: 'blur' }]">
          <el-input v-model="currentFormula.name" placeholder="请输入公式名称"></el-input>
        </el-form-item>

        <!-- 公式描述 -->
        <el-form-item label="描述">
          <el-input v-model="currentFormula.description" type="textarea" placeholder="请输入公式描述"></el-input>
        </el-form-item>

        <!-- 动态公式表达式 -->
        <el-form-item label="公式表达式">
          <div v-for="(expression, index) in currentFormula.expressions" :key="index" class="expression-item">
            <!-- 公式表达式输入框 -->
            <el-input
                v-model="expression.value"
                placeholder="例如: F = A * B / 1000"
                style="flex: 1; margin-right: 10px;"
            ></el-input>
            <!-- 表达式描述输入框 -->
            <el-input
                v-model="expression.description"
                placeholder="描述公式用途"
                style="flex: 2;"
            ></el-input>
            <!-- 删除按钮 -->
            <el-button
                type="text"
                @click="removeExpression(index)"
                v-if="currentFormula.expressions.length > 1"
            >删除</el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <!-- 新增按钮 -->
          <el-button type="primary" @click="addExpression">新增公式表达式</el-button>
        </el-form-item>

        <!-- 公式变量 -->
        <el-form-item label="公式变量">
          <el-table :data="currentFormula.variables" border size="small" style="width: 100%;">
            <!-- 变量名 -->
            <el-table-column prop="name" label="变量名" width="120">
              <template #default="{ row }">
                <el-select v-model="row.name" placeholder="选择变量名" size="small">
                  <el-option
                      v-for="(option, index) in parsedVariables"
                      :key="index"
                      :label="option"
                      :value="option"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>

            <!-- 类型 -->
            <el-table-column prop="type" label="类型" width="120">
              <template #default="{ row }">
                <el-select v-model="row.type" placeholder="选择类型" size="small">
                  <el-option label="结果" value="result"></el-option>
                  <el-option label="变量" value="variable"></el-option>
                  <el-option label="常量" value="constant"></el-option>
                </el-select>
              </template>
            </el-table-column>

            <!-- 默认值 -->
            <el-table-column prop="default_value" label="默认值" width="120">
              <template #default="{ row }">
                <el-input v-model="row.default_value" placeholder="请输入默认值" size="small"></el-input>
              </template>
            </el-table-column>

            <!-- 描述 -->
            <el-table-column prop="description" label="描述">
              <template #default="{ row }">
                <el-input v-model="row.description" placeholder="请输入描述" size="small"></el-input>
              </template>
            </el-table-column>

            <!-- 操作 -->
            <el-table-column label="操作" width="100">
              <template #default="{ $index }">
                <el-button type="text" size="small" @click="removeVariable($index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 解析表达式按钮 -->
          <!-- <el-button type="primary" @click="parseVariables">解析表达式</el-button> -->
          <!-- 新增变量按钮 -->
          <el-button type="text" @click="addVariable">新增变量</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="onSubmit">{{ isEditing ? '保存' : '添加' }}</el-button>
      </template>
    </el-dialog>


    <!-- 表格列表 -->
    <el-table :data="paginatedData" border style="width: 100%;height: 70vh" stripe>
      <!-- 公式名称 -->
      <el-table-column prop="name" label="公式名称" width="250px"/>

      <!-- 公式描述 -->
      <el-table-column prop="description" label="描述" width="250px"/>

      <!-- 表达式部分 -->
      <el-table-column label="表达式">
        <template #default="{ row }">
          <div v-if="row.expressions && row.expressions.length === 1" class="expression-list">
            <div class="expression-item">
              <span class="expression-value">{{ row.expressions[0].value }}</span>
              <span class="expression-desc">{{ row.expressions[0].description }}</span>
            </div>
          </div>
          <el-collapse v-else>
            <el-collapse-item :title="getFirstExpression(row)" name="1">
              <div v-if="row.expressions && row.expressions.length > 0" class="expression-list">
                <div
                    v-for="(expr, index) in row.expressions"
                    :key="index"
                    :style="{ paddingLeft: `${index * 20}px` }"
                    class="expression-item"
                >
                  <span class="expression-value">{{ expr.value }}</span>
                  <span class="expression-desc">{{ expr.description }}</span>
                </div>
              </div>
              <span v-else>无</span>
            </el-collapse-item>
          </el-collapse>
        </template>
      </el-table-column>

      <!-- 操作列 -->
      <el-table-column label="操作" width="180">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button type="primary" size="small" @click="edit(row)">编辑公式</el-button>
            <el-button type="danger" size="small" @click="deleteRow(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>




    <!-- 分页组件 -->
    <el-pagination
        v-if="tableData.length > pageSize"
        style="margin-top: 20px; text-align: right;"
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        v-model:currentPage="currentPage"
        @current-change="handlePageChange"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { CirclePlus } from '@element-plus/icons-vue';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  name: "FormulaLibrary",
  components: {
    CirclePlus,
  },
  data() {
    return {
      searchKeyword:"",
      tableData: [], // 保存公式数据
      currentPage: 1,
      pageSize: 10,
      showDialog: false,
      isEditing: false,
      currentFormula: {
        id: null,
        name: '',
        description: '',
        expressions: [
          { value: '', description: '' } // 默认一个公式表达式和描述
        ],
        variables: [], // 公式变量
      },
      parsedVariables: [], // 解析出的变量
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return Array.isArray(this.tableData) ? this.tableData.slice(start, end) : [];
    },
  },
  methods: {
    getFirstExpression(row) {
      if (row.expressions && row.expressions.length > 0) {
        return row.expressions[0].value;
      }
      return '无';
    },
    // 构建表达式树，识别表达式的层级关系
    buildExpressionTree(expressions) {
      const map = new Map();

      // 初始化表达式节点
      expressions.forEach((expr, index) => {
        const [result] = expr.value.split("=").map((s) => s.trim());
        map.set(result, {
          id: index,
          value: expr.value,
          description: expr.description,
          level: 0,
          children: [],
          added: false,
        });
      });

      // 建立父子关系
      expressions.forEach((expr) => {
        const [result, formula] = expr.value.split("=").map((s) => s.trim());
        const inputs = formula.match(/[a-zA-Z\u4e00-\u9fa5]+/g);

        if (inputs) {
          inputs.forEach((input) => {
            if (map.has(input)) {
              const parent = map.get(input);
              const child = map.get(result);

              // 避免重复添加
              if (!parent.children.includes(child)) {
                parent.children.push(child);
                child.level = parent.level + 1; // 设置层级
              }
            }
          });
        }
      });

      // 提取根节点
      const roots = [];
      map.forEach((node) => {
        if (node.level === 0) roots.push(node);
      });

      return roots;
    },
    // 扁平化树，用于展示所有表达式及其层级
    flattenTree(nodes) {
      const result = [];
      const traverse = (node) => {
        result.push(node);
        node.children.forEach(traverse);
      };
      nodes.forEach(traverse);
      return result;
    },
    // 新增公式表达式
    addExpression() {
      this.currentFormula.expressions.push({ value: '', description: '' });
    },
    // 删除公式表达式
    removeExpression(index) {
      if (this.currentFormula.expressions.length > 1) {
        this.currentFormula.expressions.splice(index, 1);
      }
    },
    addVariable() {
      this.parseVariables();
      this.currentFormula.variables.push({
        name: '',
        type: 'variable', // 默认类型
        default_value: null,
        description: '',
      });
    },
    removeVariable(index) {
      this.currentFormula.variables.splice(index, 1);
    },
    // 解析表达式中的变量
    parseVariables() {
      const variableSet = new Set(); // 用 Set 去重
      const regex = /[a-zA-Z\u4e00-\u9fa5_][a-zA-Z0-9\u4e00-\u9fa5_]*/g; // 匹配变量名，包括中文

      this.currentFormula.expressions.forEach((expression) => {
        const matches = expression.value.match(regex);
        if (matches) {
          matches.forEach((match) => variableSet.add(match));
        }
      });

      this.parsedVariables = Array.from(variableSet); // 转为数组供下拉框使用
      if (this.parsedVariables.length === 0) {
        ElMessage.warning("未解析到任何变量");
      } else {
        //ElMessage.success("解析完成，可选择变量");
      }
    },

    // 搜索功能
    async handleSearch() {
      this.fetchData();
    },
    async resetSearch() {
      this.searchKeyword = "";
      this.fetchData();
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/list`,
            { keywords: this.searchKeyword }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          this.tableData = response.data.data.data;
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },
    openAddDialog() {
      this.resetForm();
      this.isEditing = false;
      this.showDialog = true;
    },
    edit(row) {
      this.currentFormula = { ...row };
      this.isEditing = true;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.resetForm();
    },

    async addFormula() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/add`, this.currentFormula, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          ElMessage.success("公式添加成功");
          this.fetchData();
          this.closeDialog();
        } else {
          ElMessage.error(response.data.msg || "添加失败");
        }
      } catch (error) {
        ElMessage.error("添加请求失败");
      }
    },
    async updateFormula() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/edit`, this.currentFormula, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          ElMessage.success("公式更新成功");
          this.fetchData();
          this.closeDialog();
        } else {
          ElMessage.error(response.data.msg || "更新失败");
        }
      } catch (error) {
        ElMessage.error("更新请求失败");
      }
    },

    onSubmit() {
      this.$refs.formulaForm.validate((valid) => {
        if (valid) {
          if (this.isEditing) {
            this.updateFormula();
          } else {
            this.addFormula();
          }
        } else {
          ElMessage.error("请完善表单信息");
          return false;
        }
      });
    },
    // 重置表单
    resetForm() {
      this.currentFormula = {
        id: null,
        name: '',
        description: '',
        expressions: [{ value: '' }], // 保留一个默认表达式
        variables: [],
      };
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    async deleteRow(row) {
      try {
        await ElMessageBox.confirm('确定删除这条记录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/delete`, { id: row.id }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          this.tableData = this.tableData.filter(item => item.id !== row.id);
          ElMessage.success('删除成功');
        } else {
          ElMessage.error(response.data.msg || '删除失败');
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除请求失败');
        }
      }
    },

  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.data-center-option {
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 0;
}

.add-report-button {
  /*background-color: var(--primary-color);*/
  /*color: var(--white-color);*/
  border: none;
}

.add-report-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.el-table .el-table__header-wrapper th {
  background-color: var(--lightgray-color);
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.el-table th,
.el-table td {
  text-align: center;
  color: var(--text-color);
}

.el-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  gap: 5px;
}
.expression-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; /* 让子元素填满 el-form-item */
}
.tree {
  position: relative;
  padding-left: 20px;
}

.tree-node {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.tree-node::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 20px;
  height: 100%;
}

.tree-node:last-child::before {
  border-left: none;
}

.node-content {
  position: relative;
  background-color: #f7f8fa;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.expression-value {
  font-weight: bold;
}

.expression-desc {
  color: #909399;
  font-size: 12px;
}
.expression-list {
  display: flex;
  flex-direction: column;
}

.expression-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.expression-value {
  font-weight: bold;
  color: #303133;
  margin-right: 10px;
}

.expression-desc {
  color: #909399;
  font-size: 12px;
}

</style>
