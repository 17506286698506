<template>
  <div class="edit-report-details">
    <div class="header">
      <div class="menu">
        <div
            v-for="(item, index) in menuItems"
            :key="index"
            :class="['menu-item', { active: activeMenu === item.name }]"
            @click="changeTab(item)"
        >
          {{ item.label }}
        </div>
      </div>

      <el-button type="warning" class="doc-handle-button" @click="saveDocument">
        保存当前文档
      </el-button>
      <el-button type="primary" class="doc-handle-button" @click="downDocument">
        下载当前文档
      </el-button>
      <el-button type="success" class="doc-handle-button" @click="exportAllDocuments">
        一键生成报告
      </el-button>
    </div>

    <!-- 新增进度条 -->
    <div v-if="isGenerating" class="overlay">
      <div class="loading-container">
        <el-progress :percentage="progress" class="loading-progress" />
        <p class="loading-message">{{ loadingMessage }}</p>
      </div>
    </div>


    <!-- 三列结构 -->
    <div class="content">
      <!-- 左侧：OnlyOffice 编辑器 -->
      <div class="left-panel" style="width: 50%">
        <div v-if="filename" style="background:#fff;padding:10px;margin-bottom:5px">
          <div class="contentBtn"><span>{{ filename }}</span></div>
        </div>
        <FillingTab
            ref="fillingTab"
            :tab-data="currentTabData"
            :form-data="dialogForm"
            @updateFileData="updateFileData"
            @documentCurrentInfo="updateDocumentInfo"
        />
      </div>

      <!-- 中间：菜单项 -->
      <div class="middle-panel" style="width: 30%">
        <el-table
            :data="subMenuItems"
            border
            style="width: 100%; height: 100%;"
            table-layout="fixed"
        >
          <!-- 第一列：名称 -->
          <el-table-column prop="label" label="名称" min-width="180" align="left">
            <template #default="scope">
              <div
                  class="table-cell name-column"
                  :style="{ marginLeft: `${scope.row.level * 20}px` }"
              >
                {{ scope.row.label }}
              </div>
            </template>
          </el-table-column>
          <!-- 第二列：模板 -->
          <el-table-column label="模板" min-width="50" align="center">
            <template #default="scope">
              <div class="template-status">
                <span :class="scope.row.hasUploadedTemplate ? 'has-template' : 'no-template'">
                  {{ scope.row.hasUploadedTemplate ? '有' : '无' }}
                </span>
              </div>
            </template>
          </el-table-column>
          <!-- 第三列：操作 -->
          <el-table-column label="操作" min-width="100" align="center">
            <template #default="scope">
              <div class="table-cell action-column">
                <el-button size="mini" type="primary" @click="onMenuItemClick(scope.row)">
                  查看
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 右侧：功能区域 -->
      <div class="right-panel" style="width: 25%">
        <div class="placeholder-settings">
          <div class="order_form_container">
            <el-button type="default" class="add-category-button" @click="handleInsert('table')">
              <el-icon><Calendar /></el-icon>
              <span class="button-text">编辑内容</span>
            </el-button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <TableEditor
      v-model:isVisible="isTableEditorVisible"
      :file-data="fileData"
      :document-info="documentCurrentInfo"
      @save="saveTableContent"
  />



</template>

<script>
/* eslint-disable */  // This line disables all ESLint warnings in this file
import axios from "axios";
import FillingTab from "@/components/FillingCenter/FillingTab.vue";
import TableEditor from "@/components/Datas/TableEditor.vue";
import {ElMessage} from "element-plus";

export default {
  name: "EditReportDetails",
  components: { FillingTab , TableEditor},
  data() {
    return {
      isTableEditorVisible: false, // 控制 TableEditor 的显示
      isGenerating: false, // 控制进度条的显示
      progress: 0, // 当前进度条的百分比
      loadingMessage: "正在生成报告，请稍候...",
      isDialogVisible: false, // 初始化弹窗为隐藏状态
      dialogType: "", // 弹窗类型 ('text', 'table', 'image')
      dialogForm: {
        text: "",
        rows: 2,
        columns: 2,
        image: null,
      },
      nodeId: "",
      filename: "",
      activeMenu: "",
      currentUrl: "",
      menuItems: [],
      subMenuItems: [],
      AllDocuments: [],
      currentTabData: {},
      fileData: null, // 用于存储获取的文件数据
      documentCurrentInfo: null, // 当前文档的信息
      update_method:"",
    };
  },
  methods: {
    async saveDocument() {
      const fillingTab = this.$refs.fillingTab;
      const tempId = this.$route.params.temp_id;
      if (fillingTab && typeof fillingTab.handleDocument === "function") {
        fillingTab.handleDocument(this.filename, tempId, this.nodeId,"save");
      }
    },
    async handleInsert(type) {
      const fillingTab = this.$refs.fillingTab;
      const tempId = this.$route.params.temp_id;
      if (fillingTab && typeof fillingTab.handleDocument === "function") {
        fillingTab.handleDocument(this.filename, tempId, this.nodeId,"insert");
      }
    },
    // 从子组件接收更新的 fileData
    updateFileData(updatedFileData) {
      this.fileData = updatedFileData.path;
      this.update_method = updatedFileData.method;
      console.log("=========update_method=========");
      console.log(this.update_method);
      // 这里需要区分 是保存下载 还是 编辑内容 等区分
      if(this.update_method === "insert"){
        if(!this.fileData){
          ElMessage.error("必须先保存当前内容");
          return;
        }
        if (this.fileData) {
          this.isTableEditorVisible = true;
        }
      }
    },
    updateDocumentInfo(info) {
      console.log("接收到 documentCurrentInfo:", info);
      this.documentCurrentInfo = info; // 存储到 data 中
    },
    // 占位符 start

    // 处理编辑表格的内容 start
    saveTableContent(htmlContent) {
      console.log("保存的 HTML 内容：", htmlContent);
      ElMessage.success("表格内容已保存！");
    },
    // 处理编辑表格的内容 end
    // 处理图片上传
    handleImageUpload(file) {
      this.dialogForm.image = file;
      return false; // 阻止自动上传
    },
    // 确定插入
    confirmDialog() {
      this.isDialogVisible = false; // 关闭弹窗
      this.resetDialog(); // 重置表单数据
    },

    // 重置弹窗内容
    resetDialog() {
      this.dialogForm = {
        text: "",
        rows: 2,
        columns: 2,
        image: null,
      };
    },
    // 占位符 end

    // 一键生成报告
    async exportAllDocuments() {
      try {
        this.isGenerating = true; // 显示遮罩层
        this.progress = 0; // 初始化进度条
        this.loadingMessage = "正在生成报告，请稍候...";

        // 动态提示逻辑
        const messageInterval = setInterval(() => {
          if (this.loadingMessage.endsWith("...")) {
            this.loadingMessage = "正在生成报告，请稍候.";
          } else {
            this.loadingMessage += ".";
          }
        }, 500);

        const compayId = this.$route.params.id;
        const tempId = this.$route.params.temp_id;
        const token = localStorage.getItem("token");

        // 超时逻辑
        const timeout = setTimeout(() => {
          this.loadingMessage = "生成报告需要更多时间，请耐心等待...";
        }, 30000);

        // 向后端发送生成报告请求
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/reports/generate`,
            {
              compayId: compayId,
              temp_id: tempId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        clearInterval(messageInterval); // 清理动态提示
        clearTimeout(timeout); // 清理超时提示

        if (response.data && response.data.code === 1) {
          const { file_path, file_name } = response.data.data;

          // 拼接完整下载 URL
          const downloadUrl = `${process.env.VUE_APP_HOST}${file_path}`;

          // 提示成功并自动下载文件
          ElMessage.success("报告生成成功，正在下载...");
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.progress = 100; // 进度条完成
        } else {
          ElMessage.error(response.data.msg || "报告生成失败");
        }
      } catch (error) {
        console.error("生成报告出错:", error);
        ElMessage.error("生成报告时发生错误，请稍后重试");
      } finally {
        this.isGenerating = false; // 隐藏遮罩层
      }
    },
    // 轮询任务状态
    async pollDocumentGeneration(key) {
      try {
        while (true) {
          const response = await axios.post(`${process.env.VUE_APP_IP}/docbuilder`, {
            key: key,
          });

          if (response.data && response.data.end) {
            this.progress = 100; // 任务完成时将进度条设置为 100%
            this.isGenerating = false; // 停止显示进度条
            const downloadUrl = response.data.urls["demo.docx"];
            console.log("文档生成成功:", downloadUrl);

            // 提供下载链接
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = "output.docx";
            link.click();
            break; // 退出轮询
          } else {
            console.log("文档生成中，请稍候...");
            this.updateProgress(); // 更新进度条
            await this.delay(500); // 每2秒轮询一次
          }
        }
      } catch (error) {
        console.error("轮询任务状态失败:", error);
        this.isGenerating = false; // 停止进度条
      }
    },

    updateProgress() {
      if (this.progress < 90) {
        this.progress += 2; // 假设每次增加 2%
      }
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    // 请求右侧菜单项
    async fetchSubMenuItems(tempId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getMainMenuNavigation`,
            { temp_id: tempId },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          this.subMenuItems = this.flattenMenu(response.data.data.menu);
        } else {
          this.subMenuItems = [];
          ElMessage.error("获取菜单失败");
        }
      } catch (error) {
        this.subMenuItems = [];
        console.error("请求菜单失败:", error);
      }
    },
    // 将层级数据拍平，添加 level 信息
    flattenMenu(menu, level = 0) {
      return menu.reduce((acc, item) => {
        const currentItem = { ...item, level };
        const children = item.children
            ? this.flattenMenu(item.children, level + 1)
            : [];
        return acc.concat([currentItem, ...children]);
      }, []);
    },
    // 点击右侧菜单项加载文档，现在的逻辑是点击 查看详情，再点击保存 保存当前文档，
    // 这样步骤就多了，还容易遗忘，导致把模板给修改了。我们在点击编辑
    async onMenuItemClick(item) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getTemplateByNode`,
            { nodeId: item.id },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          const template = response.data.data.file;
          // this.fileData = template; // 存储文件数据到 fileData
          // console.log('====存储文件数据到 fileData=======');
          // console.log(template);
          this.filename = response.data.data.file.nodeName;
          this.nodeId = response.data.data.file.nodeId;
          if (template && template.path) {
            this.currentUrl = `${process.env.VUE_APP_HOST}${template.path}`;
            // 调用子组件的 initDocEditor 方法
            if(this.currentUrl){
              const fillingTabInstance = this.$refs.fillingTab;
              if (fillingTabInstance && typeof fillingTabInstance.initDocEditor === "function") {
                fillingTabInstance.currentUrl = this.currentUrl;
                fillingTabInstance.initDocEditor(item.label);
              }
            }
          } else {
            ElMessage.error("该节点未找到模板文件");
          }
        } else {
          ElMessage.error(response.data.msg || "获取模板失败");
        }
      } catch (error) {
        console.error("获取模板失败:", error);
        ElMessage.error("请求模板失败");
      }
    },
    async fetchMenuItems() {
      const token = localStorage.getItem("token");
      const tempId = this.$route.params.temp_id;

      if (!tempId) {
        console.error("缺少 temp_id 参数");
        return;
      }

      const response = await axios.post(
          `${process.env.VUE_APP_HOST}/api/documents/getMenuNavigation`,
          { temp_id: tempId },
          { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data && response.data.code === 1) {
        const menus = response.data.data.menu;
        this.menuItems = menus.map((menu, index) => ({
          ...menu,
          name: `tab${index + 1}`, // 动态生成菜单名称
        }));

        this.changeTab(this.menuItems[0]); // 默认激活第一个 tab
      } else {
        console.error("获取菜单失败:", response.data.msg);
      }

    },
    changeTab(item) {
      this.activeMenu = item.name;
      this.currentTabData = item;

      // 加载子菜单并同步到右侧表格
      this.fetchSubMenuItems(item.id);
    },
    // 保存当前文档 和 下载文档
    downDocument() {
      const fillingTab = this.$refs.fillingTab;
      const tempId = this.$route.params.temp_id;
      if (fillingTab && typeof fillingTab.handleDocument === "function") {
        fillingTab.handleDocument(this.filename, tempId, this.nodeId, "download");
      } else {
        console.error("无法调用 FillingTab 组件的导出方法");
      }
    },

  },
  mounted() {
    this.fetchMenuItems();
  },
};
</script>

<style>
.edit-report-details {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
}

.menu {
  display: flex;
  flex: 1;
  justify-content: center;
}

.menu-item {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-item:hover {
  background-color: #f2f6fc;
}

.menu-item.active {
  background-color: #409eff;
  color: white;
  border-color: #409eff;
}

.doc-handle-button {
  display: flex;
  align-items: center;
  /*background-color: #409eff;*/
  color: white;
  margin:0 10px;
}

.content {
  display: flex;
  height: calc(100vh - 120px); /* 减去 header 的高度 */
}

.left-panel {
  flex: 7;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  height: 100%;
}

.right-panel {
  flex: 3;
  background-color: #fff;
  border: 1px solid #ddd;
  height: 100%;
  overflow-y: auto;
}
.action-column {
  width: 30%!important;
  text-align: center;
}
.template-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-template {
  color: green;
  font-weight: bold;
}

.no-template {
  color: red;
  font-weight: bold;
}
.template-status {
  text-align: center;
  font-weight: bold;
}

.has-template {
  color: green;
}

.no-template {
  color: red;
}
.contentBtn{
  margin-right: 10px;
  display:inline;
}
.placeholder-settings{
  padding:20px 10px;
}
.placeholder-settings {
  display: flex;
  flex-wrap: wrap; /* 启用换行 */
  gap: 10px; /* 设置每个元素之间的间距 */
}

.placeholder-settings {
  display: flex;
  flex-wrap: wrap; /* 启用自动换行 */
  gap: 10px; /* 设置每个元素之间的间距 */
}

.order_form_container {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 设置按钮之间的间距 */
  justify-content: flex-start; /* 按钮靠左对齐 */
}


.add-category-button {
  flex: 1 1 100px; /* 每个按钮占据最小 100px 宽度 */
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; /* 防止换行 */
  box-sizing: border-box;
}
.right-panel, .el-button+.el-button {
  margin-left: 0px!important;
}

/* 遮罩层样式 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000; /* 确保层级在最上 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

/* 居中的加载容器 */
.loading-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 300px;
}

/* 提示信息样式 */
.loading-message {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* 进度条样式（可选） */
.loading-progress {
  margin-bottom: 10px;
}
</style>
